/**
 This store keeps the currently viewed page,
 globalContent.ts keeps stuff like header, footer, menus
 */
import { defineStore } from 'pinia';
import type { ResolvedContent } from '@episerver/content-delivery/src/contentResolver';
import { type ContentData, ContentResolver, ContextMode, ResolvedContentStatus } from '@episerver/content-delivery';

export const usePageContentStore = defineStore('pageContentStore', {
  state: () => ({
    page: null as ResolvedContent<ContentData> | null,
    epiMode: '' as ContextMode,
    pageType: [] as Array<string>,
  }),
  actions: {
    async loadContent(contentUrl: string) {
      const { https } = useRuntimeConfig();
      const headers = useRequestHeaders();
      if (process.server) {
        contentUrl = new URL(contentUrl, `${https ? 'https' : 'http'}://${headers['x-forwarded-host']}`).toString();
      }

      const contentResolver = new ContentResolver();

      const result = await contentResolver.resolveContent(contentUrl, true);
      this.page = result;
      
      if (this.page.content?.contentType) {
        this.pageType = this.page.content?.contentType;
      }
      if (result.status === ResolvedContentStatus.Resolved) {
        this.epiMode = result.mode;
        return ResolvedContentStatus.Resolved;
      }
      if (result.status === ResolvedContentStatus.AccessDenied) {
        return ResolvedContentStatus.AccessDenied;
      }
      if (result.status === ResolvedContentStatus.Unauthorized) {
        return ResolvedContentStatus.Unauthorized;
      }
      // No page found
      return false;
    },
  },
  getters: {
    isEpiDefault(state) {
      return state.epiMode === ContextMode.Default;
    },
    isEpiPreview(state) {
      return state.epiMode === ContextMode.Preview;
    },
    isEpiEdit(state) {
      return state.epiMode === ContextMode.Edit;
    },
    isMyPage(state) {
      return state.pageType.includes('MyAccountPage');
    },
  },
});
