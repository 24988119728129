import { default as _91_46_46_46path_939rgG6aQ0l8Meta } from "/home/appveyor/projects/swm-ecom-v2/nuxt-app/pages/[...path].vue?macro=true";
import { default as AccessDenied9g0nZh6B9mMeta } from "/home/appveyor/projects/swm-ecom-v2/nuxt-app/pages/AccessDenied.vue?macro=true";
import { default as ClientErrorTestjiJbbymSQHMeta } from "/home/appveyor/projects/swm-ecom-v2/nuxt-app/pages/ClientErrorTest.vue?macro=true";
import { default as ServerErrorTestpjpVoiNdyZMeta } from "/home/appveyor/projects/swm-ecom-v2/nuxt-app/pages/ServerErrorTest.vue?macro=true";
export default [
  {
    name: _91_46_46_46path_939rgG6aQ0l8Meta?.name ?? "path",
    path: _91_46_46_46path_939rgG6aQ0l8Meta?.path ?? "/:path(.*)*",
    meta: _91_46_46_46path_939rgG6aQ0l8Meta || {},
    alias: _91_46_46_46path_939rgG6aQ0l8Meta?.alias || [],
    redirect: _91_46_46_46path_939rgG6aQ0l8Meta?.redirect,
    component: () => import("/home/appveyor/projects/swm-ecom-v2/nuxt-app/pages/[...path].vue").then(m => m.default || m)
  },
  {
    name: AccessDenied9g0nZh6B9mMeta?.name ?? "AccessDenied",
    path: AccessDenied9g0nZh6B9mMeta?.path ?? "/AccessDenied",
    meta: AccessDenied9g0nZh6B9mMeta || {},
    alias: AccessDenied9g0nZh6B9mMeta?.alias || [],
    redirect: AccessDenied9g0nZh6B9mMeta?.redirect,
    component: () => import("/home/appveyor/projects/swm-ecom-v2/nuxt-app/pages/AccessDenied.vue").then(m => m.default || m)
  },
  {
    name: ClientErrorTestjiJbbymSQHMeta?.name ?? "ClientErrorTest",
    path: ClientErrorTestjiJbbymSQHMeta?.path ?? "/ClientErrorTest",
    meta: ClientErrorTestjiJbbymSQHMeta || {},
    alias: ClientErrorTestjiJbbymSQHMeta?.alias || [],
    redirect: ClientErrorTestjiJbbymSQHMeta?.redirect,
    component: () => import("/home/appveyor/projects/swm-ecom-v2/nuxt-app/pages/ClientErrorTest.vue").then(m => m.default || m)
  },
  {
    name: ServerErrorTestpjpVoiNdyZMeta?.name ?? "ServerErrorTest",
    path: ServerErrorTestpjpVoiNdyZMeta?.path ?? "/ServerErrorTest",
    meta: ServerErrorTestpjpVoiNdyZMeta || {},
    alias: ServerErrorTestpjpVoiNdyZMeta?.alias || [],
    redirect: ServerErrorTestpjpVoiNdyZMeta?.redirect,
    component: () => import("/home/appveyor/projects/swm-ecom-v2/nuxt-app/pages/ServerErrorTest.vue").then(m => m.default || m)
  }
]