import { defineStore } from 'pinia';
import type { ISiteSettings, ISiteSettingsResult } from '~/api-types';

export const useGlobalContentStore = defineStore('globalContentStore', {
  state: () => ({
    siteSettings: {} as ISiteSettings,
    host: '' as string,
    contentType: '' as string,
    url: '' as string,
    antiForgeryToken: '' as string,
  }),
  actions: {
    async loadFromCms() {
      // this is where we load stuff like navigation, footer, etc and place in the store
      const runtimeConfig = useRuntimeConfig();
      const host = useRequestHeaders()['x-forwarded-host'];
      const cookie = useRequestHeaders()['cookie'];
      const trueClientIp = useRequestHeaders()['true-client-ip'];
      const forwardedFor = useRequestHeaders()['x-forwarded-for'];
      const userAgent = useRequestHeaders()['user-agent'];
      this.host = `${runtimeConfig.public.environmentName === 'development' ? 'http' : 'https'}://${host}`;
      const route = useRoute();
      const url = process.server ? useRequestEvent()?.node.req.url : route.fullPath;

      this.url = url || '';
      
      let opts = {};
      if (host) {
        opts = {
          headers: { 
            'X-Epi-Host' : host, 
            cookie,
            'True-Client-IP': trueClientIp,
            'X-Forwarded-For': forwardedFor,
            'User-Agent': userAgent,
          },
        };
      }
      const result = await $fetch<ISiteSettingsResult>(`${process.client ? runtimeConfig.public.apiUrl : runtimeConfig.apiUrl}sitesettings?contentUrl=${url}`, opts);
      this.siteSettings = result.siteSettings;
      this.contentType = result.contentType || '';
      this.antiForgeryToken = result.antiForgeryToken || '';
    },
    setTheAntiForgeryToken(antiForgeryToken : string) {
      this.antiForgeryToken = antiForgeryToken;
    },
    async fetchAntiForgeryToken() {
      const runtimeConfig = useRuntimeConfig();
      const result = await $fetch<string>(`${runtimeConfig.public.apiUrl}sitesettings/xsrf/token`);
      this.antiForgeryToken = result;
    },
  },
  getters: {
    pageType(state) {
      if (this.url.includes('checkout') || this.url.includes('kassa')) {
        return 'CheckoutPage';
      }
      return state.contentType;
    },
    footer(state) {
      return state.siteSettings.footerSettings;
    },
    header(state) {
      return state.siteSettings.headerSettings;
    },
    quickMenu(state) {
      return state.siteSettings.quickMenuSettings;
    },
    pageSettings(state) {
      return state.siteSettings.pageSettings;
    },
    loginSettings(state) {
      return state.siteSettings.loginSettings;
    },
    siteNotices(state) {
      return state.siteSettings.siteNotices;
    },
    ageGate(state) {
      return state.siteSettings.ageGateSettings;
    },
    marketSettings(state) {
      return state.siteSettings.marketSettings;
    },
    newsletterSettings(state) {
      return state.siteSettings.newsletterSettings;
    },
    productSettings(state) {
      return state.siteSettings.productSettings;
    },
    checkoutSettings(state) {
      return state.siteSettings.checkoutSettings;
    },
    cartResources(state) {
      return state.siteSettings.resources.cartResources;
    },
    sharedResources(state) {
      return state.siteSettings.resources.sharedResources;
    },
    contactUsResources(state) {
      return state.siteSettings.resources.contactUsResources;
    },
    myPageResources(state) {
      return state.siteSettings.resources.myPageResources;
    },
    productResources(state) {
      return state.siteSettings.resources.productResources;
    },
    articleResources(state) {
      return state.siteSettings.resources.articleResources;
    },
    brandResources(state) {
      return state.siteSettings.resources.brandResources;
    },
    favouritesResources(state) {
      return state.siteSettings.resources.favouritesResources;
    },
    menuResources(state) {
      return state.siteSettings.resources.menuResources;
    },
    marketsResources(state) {
      return state.siteSettings.resources.marketsResources;
    },
    storePageResources(state) {
      return state.siteSettings.resources.storePageResources;
    },
    searchSettings(state) {
      return state.siteSettings.searchSettings;
    },
    registrationResources(state) {
      return state.siteSettings.resources.registrationResources;
    },
    productListingResources(state) {
      return state.siteSettings.resources.productListingResources;
    },
    searchResources(state) {
      return state.siteSettings.resources.searchPageResources;
    },
    checkoutResources(state) {
      return state.siteSettings.resources.checkoutResources;
    },
    getTheAntiForgeryToken(state) {
      return state.antiForgeryToken;
    },
    getAcceptLanguage(state) {
      return state.siteSettings.marketSettings.contentLanguage || '*';
    },
  },
});
